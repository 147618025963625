import * as React from 'react';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import RegisterUserViewModel from '../ViewModels/RegisterUserViewModel';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import authService from '../api-authorization/AuthorizeService';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
        },
    }),
);

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function RegisterUser() {
    const classes = useStyles();

    const [user, setUser] = React.useState<RegisterUserViewModel>(new RegisterUserViewModel());
    const [loaded, setLoaded] = React.useState(false);
    const [creating, setCreating] = React.useState(false);
    const [roles, setRoles] = React.useState<string[]>([]);
    const [showAlert, setShow] = React.useState(false);
    const [alert, setAlert] = React.useState(<React.Fragment />);

    React.useEffect(() => {
        getData();
    }, []);

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setShow(false);
    };

    const openAlert = (message: string, error: boolean) => {
        setAlert(
            <Alert onClose={handleClose} severity={error ? "error" : "success"}>
                {message}
            </Alert>
        );
        setShow(true);
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUser({
            ...user,
            [event.target.name]: event.target.value
        });
    }

    const getData = async () => {
        const token = await authService.getAccessToken();

        const response = await fetch('User/GetRoles', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        setRoles(data.value);
        setLoaded(true);
    }

    const createUser = async () => {
        const token = await authService.getAccessToken();
        setCreating(true);

        fetch('User/RegisterUser', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(user)
        })
            .then((response) => {
                if (response.ok) {
                    openAlert("User created successfully", false);
                    setUser(new RegisterUserViewModel());
                } else {
                    openAlert("Failed to create user, please try again.", true);
                }
                setCreating(false);
            })
            .catch(() => {
                openAlert("Failed to create user, please try again.", true);
                setCreating(false);
            });
    }

    const items = roles.map((item, index) =>
        <MenuItem key={index} value={item}>{item}</MenuItem>
    );

    return (
        <React.Fragment>
            <Paper className={classes.paper}>
                <Typography variant="h2">Register New User</Typography>
                <ValidatorForm onSubmit={createUser}>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextValidator
                                fullWidth
                                label="Name"
                                name="name"
                                variant="outlined"
                                margin="normal"
                                value={user.name}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                type="email"
                                label="Email"
                                name="email"
                                value={user.email}
                                validators={['required', 'isEmail']}
                                errorMessages={['This field is required', 'Invalid email format']}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SelectValidator
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                label="Role"
                                name="role"
                                value={user.role}
                                onChange={onChange}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                InputProps={{
                                    disabled: !loaded,
                                    startAdornment: (
                                        <React.Fragment>
                                            {loaded ? null : <CircularProgress size={20} />}
                                        </React.Fragment>
                                    )
                                }}>
                                {items}
                            </SelectValidator>
                        </Grid>
                        <Grid item xs={12}>
                            <Button fullWidth variant="contained" color="primary" type="submit" disabled={items.length <= 0 || creating}>Create</Button>
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Paper>
            {showAlert &&
                <Grid item xs={12}>
                    {alert}
                </Grid>
            }
        </React.Fragment>
    );
}