import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ActivityListViewModel } from '../../ViewModels/ActivityDateViewModel';
import authService from '../../api-authorization/AuthorizeService';
import ActivityRow from './ActivityRow';
import DenseTableCell from '../../Utilities/DenseTableCell';
import ResponsiveTable from '../../Utilities/ResponsiveTable';
import Fade from '@mui/material/Fade';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            marginBottom: theme.spacing(2)
        },
        tableBody: {
            whiteSpace: "nowrap"
        }
    })
);

interface IActivityProps {
    accountId: number;
    refresh: () => void;
    active: boolean;
}

export default function ActivityTable(props: IActivityProps) {
    const classes = useStyles();
    const [activities, setActivity] = React.useState<ActivityListViewModel[]>([]);
    const [loaded, setLoaded] = React.useState(false);

    const getData = React.useCallback(async () => {
        setLoaded(false);
        const token = await authService.getAccessToken();

        fetch(`Account/GetActivity?accountId=${props.accountId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setActivity(data.value);
                setLoaded(true);
            })
            .catch(error => {
                console.log('Activity list error: ' + error);
                setLoaded(true);
            });
    }, [props.accountId]);
    React.useEffect(() => { getData(); }, [getData]);

    const refresh = () => {
        getData();
        props.refresh();
    }

    const getItems = (activities: ActivityListViewModel[]) => {
        const today = new Date();
        const days = today.getDay() <= 4 ? 6 : 4;
        const start = (new Date());
        start.setDate(today.getDate() - days);
        const list: JSX.Element[] = [];

        for (var i = 0; i < 5; i++) {
            const activity = activities.find(f => (new Date(f.date)).toDateString() === start.toDateString());

            if (activity !== undefined) {
                list.push(<ActivityRow key={'activity' + i} activity={activity} refreshList={getData} active={props.active} />);
            } else {
                const newActivity = new ActivityListViewModel();
                newActivity.date = new Date(start);
                newActivity.accountId = props.accountId;
                list.push(<ActivityRow key={'activity' + i} activity={newActivity} refreshList={refresh} active={props.active} />);
            }

            if (start.getDay() === 5) {
                start.setDate(start.getDate() + 3);
            } else {
                start.setDate(start.getDate() + 1);
            }
        }

        return list.reverse();
    }

    const items = getItems(activities);

    return (
        <React.Fragment>
            <div className={classes.paper}>
                <ResponsiveTable aria-label="activity list" size="small">
                    <TableHead>
                        <TableRow>
                            <DenseTableCell>Date</DenseTableCell>
                            <DenseTableCell>Connect ions</DenseTableCell>
                            <DenseTableCell>Messages</DenseTableCell>
                            <DenseTableCell>Accept ance Rate</DenseTableCell>
                            <DenseTableCell>Opport unities</DenseTableCell>
                            <DenseTableCell>Follow Ups</DenseTableCell>
                            <DenseTableCell>Page Invites</DenseTableCell>
                            <DenseTableCell>Open Profile InMails</DenseTableCell>
                            <DenseTableCell>InMail Credit</DenseTableCell>
                        </TableRow>
                    </TableHead>
                    {
                        <Fade in={loaded}
                            {...(loaded ? { timeout: 1000 } : {})}>
                            <TableBody className={classes.tableBody}>
                                {items}
                            </TableBody>
                        </Fade>
                    }
                </ResponsiveTable>
            </div>
        </React.Fragment>
    );
}