import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import authService from '../api-authorization/AuthorizeService';
import LinearProgress from '@mui/material/LinearProgress';
import { LinkedInAccountListViewModel } from '../ViewModels/LinkedInAccountViewModel';
import AccountTable from './AccountTable';
import DropdownViewModel from '../ViewModels/DropdownViewModel';
import ClientFilterDropdown from '../Dropdowns/ClientFilterDropdown';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import { useLocation } from 'react-router';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Pagination from '@mui/material/Pagination';
import StatusDropdown from '../Dropdowns/StatusDropdown';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
        },
        table: {
            minWidth: 650,

        },
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
        pagination: {
            paddingTop: theme.spacing(2),
        }
    }),

);

interface LocationState {
    campaignId: number;
}

const date = new Date();


export default function AccountList() {
    const location = useLocation<LocationState>();
    const { campaignId } = location.state || {
        campaignId: 0
    };

    const classes = useStyles();
    const [accounts, setAccounts] = React.useState<LinkedInAccountListViewModel[]>([]);
    const [clients, setClients] = React.useState<DropdownViewModel[]>([]);
    const [client, setClient] = React.useState(campaignId);
    const [search, setSearch] = React.useState('');
    const [status, setStatus] = React.useState('');
    const [loaded, setLoaded] = React.useState(true);
    const [viewArchived, setViewArchived] = React.useState(false);
    const [tabValue, setTabValue] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [pageMax, setPageMax] = React.useState(0);
    const [packageFilter, setPackageFilter] = React.useState('');
    const [onboardingCount, setOnboardingCount] = React.useState(0);

    React.useEffect(() => {
        setClient(campaignId);
    }, [campaignId]);

    React.useEffect(() => {
        if (!loaded) {
            setLoaded(true);
        }
    }, [accounts, loaded]);

    

    const getData = async () => {
        setLoaded(false);

        const token = await authService.getAccessToken();

        fetch(`Account/GetAccountsList?date=${date.toJSON()}&page=${page}&search=${search}&archived=${viewArchived}&status=${status}&packageFilter=${packageFilter}&tabValue=${tabValue}&client=${client}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then((data) => {
                setAccounts(data.value.rows);
                setPageMax((Math.trunc(data.value.count / 25) + (data.value.count % 25 > 0 ? 1 : 0)));
                setOnboardingCount(data.value.onboarding);
                setClients(data.value.clients);
            })
            .catch(error => {
                setLoaded(true);
                console.log("Account list error: " + error);
            });
    };

    React.useEffect(() => {
        getData();
    }, [page]);

    React.useEffect(() => {
        if (page > 0)
            setPage(0);
        else
            getData();
    }, [viewArchived, status, packageFilter, tabValue, client]);

    const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === 'client') {
            const value = parseInt(event.target.value);
            setClient(value);
        }
        else if (event.target.name === 'statusId') {
            setStatus(event.target.value);
        }
        else if (event.target.name === 'ref_PackageId') {
            setPackageFilter(event.target.value);
        }
        else {
            setSearch(event.target.value);
        }
    }

    const onClientChange = (clientId: number) => {
        setClient(clientId);
    }

    const viewArchivedChange = (event: any, checked: boolean) => {
        setViewArchived(checked);
    }

    const handleTabChange = (event: any, newValue: any) => {
        setTabValue(newValue);
    };

    const handlePageChange = (event: any, newValue: any) => {
        setPage(newValue - 1);
    };

    const keyPress = (event: any) => {
        if (event.keyCode === 13) {
            getData();
        }
    }
   
    const list = <AccountTable accounts={accounts} refreshList={getData} date={date} />;

    return (
        <Grid container>
            <Grid item xs={12} style={{ paddingTop: "5px" }}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item>
                        <Typography variant="h2">Accounts</Typography>
                    </Grid>
                    <Grid item sm xs={12}>
                        <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
                            <Grid item xs={12} sm='auto'>
                                <FormControlLabel
                                    value={viewArchived}
                                    control={<Checkbox color="primary" />}
                                    label="View Archived"
                                    labelPlacement="end"
                                    onChange={viewArchivedChange}
                                />
                            </Grid>
                            <Grid item xs={8} sm='auto'>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    value={search}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search color="action" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={onFilterChange}
                                    onKeyDown={keyPress}
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={4} sm='auto'>
                                <Button aria-label={"Search Accounts"} onClick={getData} variant="contained" color="secondary" disabled={!loaded}>Search</Button>
                            </Grid>
                            <Grid item xs={12} sm>
                                <ClientFilterDropdown client={client} clients={clients} onChange={onClientChange} />
                            </Grid>
                            <Grid item xs={12} sm>
                                <StatusDropdown id={status} onChange={onFilterChange} />
                            </Grid>
                            <Grid item xs={12} sm>
                                <TextField
                                    select
                                    label="Package"
                                    name="ref_PackageId"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    onChange={onFilterChange}
                                    value={packageFilter}
                                    size="small"
                                >
                                    <MenuItem value={""}><i>None</i></MenuItem>
                                    <MenuItem value={"Ignition"}>Ignition</MenuItem>
                                    <MenuItem value={"Fuel"}>Fuel</MenuItem>
                                    <MenuItem value={"Boost"}>Boost</MenuItem>
                                    <MenuItem value={"Velocity"}>Velocity</MenuItem>
                                    <MenuItem value={"Ads"}>Ads</MenuItem>
                                    <MenuItem value={"Content"}>Content</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {!loaded ? <LinearProgress /> :
                    <React.Fragment>
                        <React.Fragment>
                            <Tabs
                                value={tabValue}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                            >
                                <Tab label="All Accounts" />
                                <Tab label="Accounts i am Manager of" />
                                <Tab label="Accounts i am Executive of" />
                                <Tab label="Accounts i am Team Leader of" />
                                <Tab label="Accounts i am Velocity Manager of" />
                                <Tab label="Accounts i am Client Director of" />
                                <Tab label={
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>Onboarding Accounts</Grid>
                                        <Grid item>
                                            <Chip color="warning" size="small" label={onboardingCount} />
                                        </Grid>
                                    </Grid>}
                                />
                            </Tabs>
                        </React.Fragment>
                        {list}
                    </React.Fragment>
                }
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="flex-end" alignItems="center" className={classes.pagination}>
                    <Grid item>
                        <Pagination page={page + 1} onChange={handlePageChange} count={pageMax} showFirstButton showLastButton />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}