import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
//import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
//import Visibility from '@mui/icons-material/Visibility';
//import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useLocation } from 'react-router';
import LinkedInAccountViewModel, { CreateMainExecChangedViewModel, UserAccountViewModel } from '../../ViewModels/LinkedInAccountViewModel';
import authService from '../../api-authorization/AuthorizeService';
import LinearProgress from '@mui/material/LinearProgress';
import ExecutivesList from './ExecutivesList';
import LeadList from './LeadList';
import Activity from './Activity';
import Messages from './Messages';
import Notes from './Notes';
import Status from './Status';
import InfoEdit from '../../Utilities/InfoEdit';
import AccountInfo from './AccountInfo';
import Skeleton from '@mui/lab/Skeleton';
import Edit from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import { ValidatorForm } from 'react-material-ui-form-validator';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import UserDropdown from '../../Dropdowns/UserDropdown';
import DialogContentText from '@mui/material/DialogContentText';
import DropdownViewModel from '../../ViewModels/DropdownViewModel';
import CopyToClipboard from 'react-copy-to-clipboard';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { PermissionCheck } from '../../Contexts/PermissionCheck';
import SurveyCheckViewModel from '../ViewModels/SurveyCheck';
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            marginBottom: theme.spacing(2)
        },
        showButton: {
            padding: 8
        },
        bottomSpacing: {
            marginBottom: theme.spacing(2)
        },
        password: {
            paddingBottom: 0
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12
        },
        topSpacing: {
            marginTop: theme.spacing(2)
        },
        dividerColour: {
            backgroundColor: '#fcfcfc'
        }
    })
);

interface LocationState {
    accountId: number;
}

export default function Account() {
    const classes = useStyles();
    const location = useLocation<LocationState>();
    const { accountId } = location.state || {
        accountId: 0
    };
    const [loaded, setLoaded] = React.useState(false);
    const [account, setAccount] = React.useState(new LinkedInAccountViewModel());
    const [showPassword, setShow] = React.useState(false);
    const [exec, setExec] = React.useState<UserAccountViewModel>({ accountId: accountId, userId: '', startDate: new Date(), endDate: new Date() });
    const [velocityManager, setVelocityManager] = React.useState<UserAccountViewModel>({ accountId: accountId, userId: '', startDate: new Date(), endDate: new Date() });
    const [active, setActive] = React.useState(false);
    const [openExecDialog, setDialogOpen] = React.useState(false);
    const [openVelocityDialog, setVelocityDialogOpen] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [error, setError] = React.useState('');
    const [users, setUsers] = React.useState<DropdownViewModel[]>([]);
    const [surveyLink, setSurveyLink] = React.useState<SurveyCheckViewModel | null>(null);
    const [openSurveyDialog, setSurveyDialogOpen] = React.useState(false);
    const [note, setNote] = React.useState('');
    const [checkRefreshNote, setCheckRefreshNote] = React.useState(false);

    React.useEffect(() => {
        getData();
    }, [accountId]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNote(event.target.value);
    }

    const getData = async () => {
        setLoaded(false);
        const token = await authService.getAccessToken();

        fetch(`Account/GetAccount?id=${accountId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setAccount(data.value);

                const isActive = new Date(data.value.actualStartDate).setHours(0, 0, 0, 0) <= (new Date()).setHours(0, 0, 0, 0) && (!(data.value.agreementEnd) || new Date(data.value.agreementEnd!).setHours(0, 0, 0, 0) > (new Date()).setHours(0, 0, 0, 0));
                setActive(isActive);

                fetch(`User/GetUsersNotOnAccount?accountId=${accountId}`, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                })
                    .then(response => response.json())
                    .then(data => {
                        setUsers(data.value);
                        setLoaded(true);
                    })
                    .catch(error => {
                        setError('A server error has occurred, please try again.');
                        setLoaded(true);
                    });
            })
            .catch(error => {
                console.log('Account error: ' + error);
                setLoaded(true);
            });
    }

    const updatePassword = async (value: string | number | Date | null) => {
        try {
            const token = await authService.getAccessToken();
            const value2 = encodeURIComponent(value as string);
            const response = await fetch(`Account/UpdatePassword?id=${accountId}&password=${value2}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                method: 'POST'
            });

            if (response.ok) {
                setAccount({
                    ...account,
                    password: value as string,
                });
            }
            return true;

        } catch (error) {
            console.log('Update error: ' + error);
            return true;
        }
    }

    const updateUsername = async (value: string | number | Date | null) => {
        try {
            const token = await authService.getAccessToken();

            const response = await fetch(`Account/UpdateUsername?id=${accountId}&username=${value}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                method: 'POST'
            });

            if (response.ok) {
                setAccount({
                    ...account,
                    userName: value as string,
                });
            }
            return true;

        } catch (error) {
            console.log('Update error: ' + error);
            return true;
        }
    }
    const updateMainExecutive = async () => {
        setSubmitting(true);
        const token = await authService.getAccessToken();

        fetch('Campaign/CreateMainExecChangeNote', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(new CreateMainExecChangedViewModel(accountId, note, exec.userId))
        })
            .then(response => response.text())
            .then(data => {
                fetch('Account/AssignMainExecutive', {
                    method: 'POST',
                    headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
                    body: JSON.stringify(exec)
                })
                    .then(response => response.text())
                    .then(data => {
                        setSubmitting(false);
                        setExec({ accountId: accountId, userId: '', startDate: new Date(), endDate: new Date() });
                        setDialogOpen(false);
                        getData();
                    })
                    .catch(error => {
                        setError('A server error has occurred, please try again.');
                    });
            })
            .catch(error => {
                setSubmitting(false);
                setError('A server error has occurred, please try again.');
            });
    }
    const updateVelocityManager = async () => {
        setSubmitting(true);
        const token = await authService.getAccessToken();


        fetch('Account/AssignVelocityManager', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(velocityManager)
        })
            .then(response => response.text())
            .then(data => {
                setSubmitting(false);
                setVelocityManager({ accountId: accountId, userId: '', startDate: new Date(), endDate: new Date() });
                setVelocityDialogOpen(false);
                getData();
            })
            .catch(error => {
                setError('A server error has occurred, please try again.');
            });

    }
    const handleShowPassword = () => {
        setShow(!showPassword);
    }
    const openDialog = () => {
        setDialogOpen(true);
    }
    const onChange = (value: number | string, name: string, client: string) => {
        setExec({
            ...exec,
            [name]: value
        });
    }

    const onClose = () => {
        if (!submitting) {
            setExec({ accountId: accountId, userId: '', startDate: new Date(), endDate: null });
            setDialogOpen(false);
        }
    }

    const openVelocityManagerDialog = () => {
        setVelocityDialogOpen(true);
    }
    const onVelocityChange = (value: number | string, name: string, client: string) => {
        setVelocityManager({
            ...velocityManager,
            [name]: value
        });
    }

    const onVelocityClose = () => {
        if (!submitting) {
            setVelocityManager({ accountId: accountId, userId: '', startDate: new Date(), endDate: null });
            setVelocityDialogOpen(false);
        }
    }

    const getSurveyLink = async (event: React.MouseEvent<HTMLButtonElement>) => {
        const token = await authService.getAccessToken();
        fetch(`CustomerSurveySatifaction/GetSurveyLink?accountId=${accountId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setSurveyLink(data.value);
                }
                setSurveyDialogOpen(true);
            })
            .catch(error => {
            });
    }

    const sendSurvey = async (event: React.MouseEvent<HTMLButtonElement>) => {
        setSubmitting(true);
        const token = await authService.getAccessToken();
        fetch(`CustomerSurveySatifaction/SendSurvey?accountId=${accountId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setSurveyLink(null);
                    setSurveyDialogOpen(false);
                }
                setSubmitting(false);
            })
            .catch(error => {
                setSubmitting(false);
            });
    }
    const handleAutoSendCheckbox = async () => {
        setSubmitting(true);
        const token = await authService.getAccessToken();
        fetch(`CustomerSurveySatifaction/ChangeOptIn?accountId=${accountId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    getData()
                }
                setSubmitting(false);
            })
            .catch(error => {
                setSubmitting(false);
            });

    }
    const handleSurveyClose = () => {
        setSurveyDialogOpen(false);
    };


    const catchUpButton = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: '/DataCapture/ClientCatchUpForm',
                state: { accountId }
            }}
            {...props} />
    ));

    const refreshNotes = () => {
        setCheckRefreshNote(true);
    }

    return (
        <Grid container spacing={2}>
            {!loaded && <LinearProgress />}
            <Grid item xs={12} lg={3} style={{ marginTop: "10px" }}>
                <AccountInfo accountId={accountId} refreshNotes={refreshNotes} refreshAccount={getData} />
                <Status accountId={accountId} status={account.status} clientId={account.campaignId} refresh={getData} />
                <div className={classes.paper}>
                    <Grid container>
                        <Grid item>
                            <Typography variant="h6">Client Manager</Typography>
                            <Typography>
                                {loaded ? account.campaignTeamLeader !== null && account.campaignTeamLeader.length > 0 ?
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <Avatar src={account.campaignTeamLeaderImageUrl}>
                                                {account.campaignTeamLeader.substring(0, 2)}
                                            </Avatar>
                                        </Grid>
                                        <Grid item>
                                            {account.campaignTeamLeader}
                                        </Grid>
                                    </Grid> : 'None Assigned'
                                    : <Skeleton />
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.paper}>
                    <Grid container>
                        <Grid item>
                            <Typography variant="h6">Operations Manager</Typography>
                            <Typography>
                                {loaded ? (account.campaignOpsManager !== null && account.campaignOpsManager.length > 0) ?
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <Avatar src={account.campaignOpsManagerImageUrl}>
                                                {account.campaignOpsManager.substring(0, 2)}
                                            </Avatar>
                                        </Grid>
                                        <Grid item>
                                            {account.campaignOpsManager}
                                        </Grid>
                                    </Grid> : 'None Assigned'
                                    : <Skeleton />
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <Divider className={classes.paper} />
                <div className={classes.paper}>
                    <Typography variant="h6">Main Executive</Typography>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Typography>
                                {loaded ?
                                    account.mainExecutive.length > 0 ?
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item>
                                                <Avatar src={account.mainExecutiveImageUrl}>
                                                    {account.mainExecutive.substring(0, 2)}
                                                </Avatar>
                                            </Grid>
                                            <Grid item>
                                                {account.mainExecutive}
                                            </Grid>
                                        </Grid>
                                        : 'None Assigned'
                                    : <Skeleton />
                                }
                            </Typography>
                        </Grid>
                        <PermissionCheck permission="Actions.UpdateMainExec">
                            <Grid item>
                                <IconButton size="small" onClick={openDialog} style={{ marginLeft: 8 }}>
                                    <Edit color="secondary" />
                                </IconButton>
                            </Grid>
                        </PermissionCheck>
                    </Grid>
                </div>
                {account.packageName.indexOf("Velocity") !== -1 &&
                    <div className={classes.paper}>
                        <Typography variant="h6">Velocity Manager</Typography>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Typography>
                                    {loaded ?
                                        account.velocityManager.length > 0 ?
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item>
                                                    <Avatar src={account.velocityManagerImageUrl}>
                                                        {account.mainExecutive.substring(0, 2)}
                                                    </Avatar>
                                                </Grid>
                                                <Grid item>
                                                    {account.velocityManager}
                                                </Grid>
                                            </Grid>
                                            : 'None Assigned'
                                        : <Skeleton />
                                    }
                                </Typography>
                            </Grid>
                            <PermissionCheck permission="Actions.UpdateMainExec">
                                <Grid item>
                                    <IconButton size="small" onClick={openVelocityManagerDialog} style={{ marginLeft: 8 }}>
                                        <Edit color="secondary" />
                                    </IconButton>
                                </Grid>
                            </PermissionCheck>
                        </Grid>
                    </div>
                }
                <Divider className={classes.paper} />
                <PermissionCheck permission="Actions.UpdateMainExec">
                    <ExecutivesList accountId={accountId} users={users} />
                </PermissionCheck>
            </Grid>

            <Grid item xs={12} lg={6} className={classes.dividerColour}>
                <div className={classes.topSpacing} />
                <Activity accountId={accountId} active={active} clientName={account.campaignName} accountName={account.name} accountManager={account.campaignOpsManager} />
                <Notes clientId={account.campaignId} accountId={accountId} checkRefreshNote={checkRefreshNote} setCheckRefreshNote={setCheckRefreshNote} />
            </Grid>
            <Grid item xs={12} lg={3}>
                <div className={classes.topSpacing} />
                <Typography variant="h5">Credentials</Typography>
                <div className={classes.paper}>
                    {loaded ?
                        <Grid container>
                            <Grid item xs={12} style={{ paddingTop: 10 }}>
                                <InfoEdit info={account.userName} hasClipboard={true} label="Username" onSave={updateUsername} />
                            </Grid>
                            <Grid item xs={12} style={{ paddingTop: 10 }}>
                                <InfoEdit info={account.password} hasClipboard={true} label="Password" onSave={updatePassword} showPassword={showPassword} togglePassword={handleShowPassword} />
                            </Grid>
                        </Grid>
                        : <Skeleton height={100} />
                    }
                </div>

                <Divider className={classes.paper} />
                <Messages accountId={accountId} />
                <LeadList accountId={accountId} googleSheetId={account.googleSheetId} active={active} />
                <br />
                    <Button disabled={account.mainExecutiveId === ""} variant="outlined" color="secondary" onClick={getSurveyLink} fullWidth>
                        Send Survey
                    </Button>
                <Button variant="outlined" color="secondary" fullWidth component={catchUpButton}>Client Catch Up Form</Button>
            </Grid>
            <Dialog open={openExecDialog} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth>
                <ValidatorForm onSubmit={updateMainExecutive}>
                    <DialogTitle id="form-dialog-title">Assign Executive</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {error}
                        </DialogContentText>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <UserDropdown users={users} id={exec.userId} label="Executive" name="userId" onChange={onChange} required={true} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Reason for change"
                                    name="note"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={note}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="secondary" disabled={submitting}>Cancel</Button>
                        <Button type="submit" color="primary" disabled={submitting}>
                            Submit
                            {submitting && <CircularProgress color='primary' size={24} className={classes.buttonProgress} />}
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
            <Dialog open={openVelocityDialog} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth>
                <ValidatorForm onSubmit={updateVelocityManager}>
                    <DialogTitle id="form-dialog-title">Assign Velocity Manager</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {error}
                        </DialogContentText>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <UserDropdown users={users} id={velocityManager.userId} label="Velocity Manager" name="userId" onChange={onVelocityChange} required={true} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Reason for change"
                                    name="note"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={note}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onVelocityClose} color="secondary" disabled={submitting}>Cancel</Button>
                        <Button type="submit" color="primary" disabled={submitting}>
                            Submit
                            {submitting && <CircularProgress color='primary' size={24} className={classes.buttonProgress} />}
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
            <Dialog
                open={openSurveyDialog}
            >
                <DialogContent>
                    {surveyLink != null &&
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h5">CSS Survey</Typography>
                            </Grid>
                            <Grid item xs={11}>
                                <Typography variant="body1">{surveyLink?.link}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <CopyToClipboard text={surveyLink.link}
                                    onCopy={() => { }}>
                                    <IconButton size="small">
                                        <FileCopyIcon />
                                    </IconButton>
                                </CopyToClipboard>
                            </Grid>
                            {!surveyLink.autoSend &&
                                < Grid item xs={12}>
                                    <Alert severity={surveyLink.completed ? "success" : surveyLink.disabledMessage === "Email has been sent for the week" ? "info":"warning"} >{surveyLink.disabledMessage}</Alert>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Button fullWidth onClick={sendSurvey} color="secondary" disabled={submitting || surveyLink?.completed || !surveyLink?.autoSend}>Auto Send Survey</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel control={<Checkbox checked={account.autoSendCSS} name="reasonForCall" onChange={handleAutoSendCheckbox} />} label="Opt into auto send" />
                            </Grid>
                        </Grid>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSurveyClose} color="secondary" disabled={submitting}>Close</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}