import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';
import { TextValidator } from 'react-material-ui-form-validator';
import { IClientDiscoveryFormTabProps } from "./ClientDiscoveryForm";



export default function ClientDiscoveryFinal({ data, handleInputChange }: IClientDiscoveryFormTabProps) {
    return (
        <Paper sx={{ padding: "8px" }}>
            <Grid container spacing={1}>

                <Grid item xs={12}>
                    <Typography variant="h4">Final Section</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        Next Steps:
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Start Date:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        "Typically our onboarding process takes up to 5 days, is this a good time for you to start or do you have a particular date in mind?"
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="startDate"
                        value={data.startDate}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}

                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Cell/Mobile Number:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        "Occasionally, I may also need to call you with a question or update on your campaign. What is the best number to reach you at?"
                    </Typography>
                    <Typography variant="body1">
                        "If you could also save our number (UK = 0161 5184740, US = +1 (708) 669 1047, Singapore =  65 6027 9079) so you know it is me calling that would be great!"
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="telephone"
                        value={data.telephone}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}

                    />
                </Grid>
                {!data.isContent &&
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h6">Whose account will we be using?</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                name="accountName"
                                value={data.accountName}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                multiline
                                validators={['required']}
                                errorMessages={['This field is required']}

                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">Log In Details:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                "Fantastic! So what we can do now, as we have some time left on our call and to save some back and forth over email, if you give me your log in details now, I'll log in build a search for you on the call and we can actually get started sending connections today!"
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                label="Username"
                                name="logInUser"
                                value={data.logInUser}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                multiline
                                validators={['required']}
                                errorMessages={['This field is required']}

                            />

                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                label="Password"
                                name="logInPass"
                                value={data.logInPass}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                multiline
                                validators={['required']}
                                errorMessages={['This field is required']}

                            />
                        </Grid>
                    </>
                }
                {data.isBoost &&
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h6">Description of Next Steps:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={data.nextStepsLogInDetails} name="nextStepsLogInDetails" onChange={handleInputChange} />} label="LinkedIn Log In Details" />
                                <FormControlLabel control={<Checkbox checked={data.nextStepsSearchSetUp} name="nextStepsSearchSetUp" onChange={handleInputChange} />} label="Search Set Up" />
                                <FormControlLabel control={<Checkbox checked={data.nextStepsProfileReview} name="nextStepsProfileReview" onChange={handleInputChange} />} label="Profile Review" />
                                <FormControlLabel control={<Checkbox checked={data.nextStepsGoLive} name="nextStepsGoLive" onChange={handleInputChange} />} label="Campaign goes Live" />
                                <FormControlLabel control={<Checkbox checked={data.nextStepsReview} name="nextStepsReview" onChange={handleInputChange} />} label="Message to review" />
                            </FormGroup>
                        </Grid>
                    </>
                }
                <Grid item xs={12}>
                    <Typography variant="h6">Recurring Call booked:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        "So for the first month, let's catch up every week. Then after that, we can move to every other week. So typically which day in the week is quietest for you...great so I'll sent that to recur every 2 weeks for the whole campaign, but every week for the next month."

                        Day, Time, Weekly/Bi-weekly
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="recurringCall"
                        value={data.recurringCall}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}

                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">CSS Explained:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        "Every month I am going to send you a very short Customer Satisfaction Survey, it is just a really quick survey asking how the campaign is progressing. It would mean the world to me if you can get this filled out when I sent it to you as it gives me and my Manager an indication of how I am doing and if we can make the service better for you. This also goes to our CEO so keeps him in the loop of how this campaign is progressing too.  Is that alright?"
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="customerSurvey"
                        value={data.customerSurvey}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}

                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Communication Channel:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        "Many of our Clients find it useful to have a Slack channel with us, is this something we cound set up for you too? (If they dont have Slack, please find out ehat workspace they use)"
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="communicationChannel"
                        value={data.communicationChannel}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}

                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Address any final questions or concerns from The Client</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        This is the first impression you are giving to The Client of their experience working with StraightIn and You, if they don't trust you now, they never will!
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextValidator
                        name="nextStepQuestions"
                        value={data.nextStepQuestions}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                        multiline
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
}