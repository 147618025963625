import * as React from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { Report, models } from 'powerbi-client';
import 'powerbi-report-authoring';
import authService from '../../api-authorization/AuthorizeService';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import DropdownViewModel from '../../ViewModels/DropdownViewModel';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { PermissionsContext } from '../../Contexts/PermissionsContext';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iframe: {
            flexGrow: 1,
            border: 'none',
            margin: 0,
            padding: 0,
            height: 'calc(100vh - 150px)'
        },
        dropdown: {
            marginTop: "20px"
        }
    }),
);

export default function DashboardContainer() {
    const classes = useStyles();
    const [token, setToken] = React.useState('');
    const [report, setReport] = React.useState<Report | null>(null);
    const [layout, setLayout] = React.useState({ visualsLayout: {} });
    const [id, setId] = React.useState('');
    const [dropdownId, setDropdownId] = React.useState('');
    const [dropdownUsers, setDropdownUsers] = React.useState<DropdownViewModel[]>([]);
    const { checkHasPermission } = React.useContext(PermissionsContext);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDropdownId(event.target.value);
    };

    React.useEffect(() => {
        getPBIToken();
        getDropdownUsers();
    }, []);

    React.useEffect(() => {
        if (token !== '' && report !== null)
            createLayout();
    }, [report]);

    const getDropdownUsers = async () => {
        const token = await authService.getAccessToken();

        fetch(`User/GetExecutiveDropdown`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        }).then(response => response.json())
            .then(data => {
                setDropdownUsers(data.value);
            });
    }

    const idFilter = {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
            table: "AccountSnapShots",
            column: "MainExecutiveId"
        },
        operator: "In",
        values: [dropdownId === '' ? id : dropdownId],
        filterType: models.FilterType.Basic,
        requireSingleSelection: true
    }

    const createLayout = async () => {
        if (report !== undefined && report !== null) {
            var v = await (await report.getActivePage()).getVisuals();
            console.log(v);
        }
        setLayout({
            visualsLayout: {
                "7a3228aea4cd5ee013dd": { // gauge
                    x: -100,
                    y: 20,
                    displayState: {
                        mode: models.VisualContainerDisplayMode.Visible
                    }
                },
                "da3519a41e7d58951980": { // multi card
                    x: 0,
                    y: 300,
                    displayState: {
                        mode: models.VisualContainerDisplayMode.Visible
                    }
                },
                "570b09a55cba8251c347": { // retained accounts
                    x: 350,
                    y: 400,
                    displayState: {
                        mode: models.VisualContainerDisplayMode.Visible
                    }
                },
                "9ee67bd096751dac5819": { // opportunities per day
                    x: 700,
                    y: 20,
                    height: 350,
                    displayState: {
                        mode: models.VisualContainerDisplayMode.Visible
                    }
                }
            }
        })
    }

    const getPBIToken = async () => {
        const user = await authService.getUser();

        if (user !== null) {
            setId(user.sub);
            const token = await authService.getAccessToken();

            fetch(`Report/GetGenericPBIToken`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(response => response.json())
                .then(data => {
                    setToken(data.value.embedToken);
                });
        }
    }

    return (token !== '' &&
        <React.Fragment>
            {checkHasPermission("") &&
                <TextField
                    id="outlined-select-currency"
                    select
                    label="Select"
                    size="small"
                    value={dropdownId}
                    onChange={handleChange}
            className={classes.dropdown}
            fullWidth
                >
                    {dropdownUsers.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.value}
                        </MenuItem>
                    ))}
                </TextField>
            }
            <div style={{ display: 'flex', width: '100%', height: '100%', flexDirection: 'column', overflow: 'hidden' }}>
                <PowerBIEmbed
                    cssClassName={classes.iframe}
                    embedConfig={{
                        type: 'report',
                        id: "194fca09-1114-4d5f-8110-5b5b12082a82",
                        embedUrl: "https://app.powerbi.com/reportEmbed?reportId=194fca09-1114-4d5f-8110-5b5b12082a82",
                        accessToken: token,
                        tokenType: models.TokenType.Aad,
                        filters: [idFilter],
                        settings: {
                            visualSettings: {
                                visualHeaders: [
                                    {
                                        settings: {
                                            visible: false
                                        }
                                    }
                                ]
                            },
                            layoutType: models.LayoutType.Custom,
                            customLayout: {
                                displayOption: models.DisplayOption.FitToPage,
                                pagesLayout: {
                                    "ReportSection96272c73c43feaf96bb9": layout
                                }
                            },
                            panes: {
                                filters: {
                                    visible: false
                                },
                                pageNavigation: {
                                    visible: false
                                }
                            }
                        }
                    }}

                    eventHandlers={
                        new Map([
                            ['loaded', (event: any) => {
                                console.log('Report loaded');
                                setReport(event.srcElement.powerBiEmbed as Report);
                            }],
                            ['rendered', () => { console.log('Report rendered'); }],
                            ['error', (event: any) => {
                                console.log(event.detail);
                            }],
                        ])
                    }
                />
            </div>
        </React.Fragment>
    );
}