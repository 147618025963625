import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CreateOpportunityViewModel } from '../ViewModels/LinkedInAccountViewModel';
import CircularProgress from '@mui/material/CircularProgress';
import authService from '../api-authorization/AuthorizeService';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import MuiPhoneNumber from 'material-ui-phone-number';
import CampaignViewModel from '../ViewModels/CampaignViewModel';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        }
    })
);

interface ILeadProps {
    accountId: number;
    open: boolean;
    close: (refresh: boolean) => void;
}

class DropdownViewModel {
    id: number = 0;
    value: string = '';
}

export default function OpportunityDialog(props: ILeadProps) {
    const classes = useStyles();
    const [lead, setLead] = React.useState<CreateOpportunityViewModel>(new CreateOpportunityViewModel(props.accountId));
    const [submitting, setSubmitting] = React.useState(false);
    const [error, setError] = React.useState('');
    const [campaign, setCampaign] = React.useState<CampaignViewModel>(new CampaignViewModel());
    const companySizeList = ["1-10", "11-50", "51-200", "201-500", "501-1000", "1001-5000", "5001-10,000", "10,000+"]
    const [industryLabels, setIndustryLabels] = React.useState<string[]>([]);
    const [seniorityLevels, setSeniorityLevels] = React.useState<string[]>([]);
    const [leadSources, setLeadSources] = React.useState<string[]>([]);
    const [contactMethods, setContactMethods] = React.useState<DropdownViewModel[]>([]);


    React.useEffect(() => {
        initialise();
    }, []);

    React.useEffect(() => {
        getCampaign();
    }, [props.open])

    const initialise = async () => {
        fetch('Account/GetIndustries')
            .then(response => response.json())
            .then(data => {
                setIndustryLabels(data.value);
            });
        fetch('Account/GetLeadSources')
            .then(response => response.json())
            .then(data => {
                setLeadSources(data.value);
            });
        fetch('Account/GetSeniorityLevels')
            .then(response => response.json())
            .then(data => {
                setSeniorityLevels(data.value);
            });
        const token = await authService.getAccessToken();

        const response = await fetch('Account/GetContactMethods', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        setContactMethods(data.value);
    }

    const getCampaign = async () => {
        const token = await authService.getAccessToken();
        fetch('Account/GetCampaignFromAccount?id=' + props.accountId, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        })
            .then(response => response.json())
            .then(data => {
                setCampaign(data.value);
            })
            .catch(error => {
                setError('A server error has occurred, please try again.');
            });
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLead({
            ...lead,
            [event.target.name]: event.target.value
        });
    }

    function onChangeP(value: any) {
        setLead({
            ...lead,
            phoneNumber: value
        })
    }

    const onClose = () => {
        if (!submitting) {
            setLead(new CreateOpportunityViewModel(props.accountId));
            props.close(false);
        }
    }

    const submit = async () => {
        setSubmitting(true);
        const token = await authService.getAccessToken();

        fetch('Account/CreateOpportunity', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(lead)
        })
            .then(response => response.text())
            .then(data => {
                setSubmitting(false);
                setLead(new CreateOpportunityViewModel(props.accountId));
                props.close(true);
            })
            .catch(error => {
                setError('A server error has occurred, please try again.');
            });
    }

    const items = companySizeList.map((value, index) => {
        return <MenuItem key={index} value={value}>{value}</MenuItem>;
    });
    const seniorityLevelsList = seniorityLevels.map((value, index) => {
        return <MenuItem key={index} value={value}>{value}</MenuItem>;
    });
    const leadSourcesList = leadSources.map((value, index) => {
        return <MenuItem key={index} value={value}>{value}</MenuItem>;
    });
    const contactMethodList = contactMethods.map((item, index) =>
        <MenuItem key={index} value={item.id}>{item.value}</MenuItem>);
    ValidatorForm.addValidationRule('notGmailHotmail', (value) => {
        if (value.includes("hotmail") || value.includes("gmail")) {
            return false;
        }
        return true;
    });

    return (
        <Dialog open={props.open} aria-labelledby="form-dialog-title">
            <ValidatorForm onSubmit={submit}>
                <DialogTitle id="form-dialog-title">Add Lead</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {error}
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextValidator
                                variant="outlined"
                                autoFocus
                                margin="dense"
                                fullWidth
                                label="First Name"
                                name="firstName"
                                value={lead.firstName}
                                onChange={onChange}
                                validators={['required']}
                                errorMessages={['Required']}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextValidator
                                variant="outlined"
                                autoFocus
                                margin="dense"
                                fullWidth
                                label="Last Name"
                                name="lastName"
                                value={lead.lastName}
                                onChange={onChange}
                                validators={['required']}
                                errorMessages={['Required']}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label="Profile Link"
                                name="link"
                                value={lead.link}
                                onChange={onChange}
                                validators={['required']}
                                errorMessages={['Required']}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextValidator
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label="Company Name"
                                name="companyName"
                                value={lead.companyName}
                                onChange={onChange}
                                validators={['required']}
                                errorMessages={['Required']}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextValidator
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label="Job Title"
                                name="jobTitle"
                                value={lead.jobTitle}
                                onChange={onChange}
                                validators={['required']}
                                errorMessages={['Required']}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            {seniorityLevels.length > 0 &&
                                <SelectValidator
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    label="Seniority Level"
                                    name="seniorityLevel"
                                    value={lead.seniorityLevel}
                                    onChange={onChange}
                                    validators={['required']}
                                    errorMessages={['Required']}
                                >
                                    {seniorityLevelsList}
                                </SelectValidator>
                            }
                        </Grid>
                        <Grid item xs={6}>
                            <TextValidator
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label="Location"
                                name="location"
                                value={lead.location}
                                onChange={onChange}
                                validators={['required']}
                                errorMessages={['Required']}
                            />
                        </Grid>
                        {campaign.campaignName.includes("StraightIn Outbound") &&
                            <Grid item xs={12}>
                                <SelectValidator
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    label="Country"
                                    name="country"
                                    value={lead.country}
                                    onChange={onChange}
                                >
                                    <MenuItem key={""} value={""}>{""}</MenuItem>
                                    <MenuItem key={"US"} value={"US"}>{"US"}</MenuItem>
                                    <MenuItem key={"Europe"} value={"Europe"}>{"Europe"}</MenuItem>
                                </SelectValidator>
                            </Grid>
                        }
                        <Grid item xs={6}>
                            <MuiPhoneNumber
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                defaultCountry={'gb'}
                                countryCodeEditable={false}
                                disableAreaCodes={true}
                                label='Phone Number'
                                value={lead.phoneNumber}
                                onChange={onChangeP}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextValidator
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label="Email"
                                name="email"
                                value={lead.email}
                                onChange={onChange}
                                validators={['isEmail', 'notGmailHotmail']}
                                errorMessages={['Invalid Format', 'Gmail and Hotmail are not allowed']}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectValidator
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label="Size Of Company"
                                name="sizeOfCompany"
                                value={lead.sizeOfCompany}
                                onChange={onChange}
                                validators={['required']}
                                errorMessages={['Required']}
                            >
                                {items}
                            </SelectValidator>
                        </Grid>
                        <Grid item xs={12}>
                            {industryLabels.length > 0 &&
                                <Autocomplete
                                    onChange={(event: any, newValue: string | null) => {
                                        setLead({ ...lead, industry: newValue ?? "" });
                                    }}
                                    value={lead.industry}
                                    options={industryLabels}
                                    autoHighlight
                                    autoComplete
                                    autoSelect
                                    fullWidth

                                    renderInput={(params: any) =>
                                        <TextValidator {...params}
                                            label="Select Industry"
                                            variant="outlined"
                                            value={lead.industry}
                                            margin="dense"
                                            validators={['required']}
                                            errorMessages={['Required']}
                                        />}
                                />}
                        </Grid>
                        <Grid item xs={6}>
                            {contactMethods.length > 0 &&
                                <SelectValidator
                                    label="Lead Type"
                                    name="preferredContactMethodId"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    onChange={onChange}
                                    value={lead.preferredContactMethodId}
                                >
                                    {contactMethodList}
                                </SelectValidator>
                            }
                        </Grid>
                        <Grid item xs={6}>
                            {leadSources.length > 0 &&
                                <SelectValidator
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    label="Lead Source"
                                    name="leadSource"
                                    value={lead.leadSource}
                                    onChange={onChange}
                                    validators={['required']}
                                    errorMessages={['Required']}
                                >
                                    {leadSourcesList}
                                </SelectValidator>
                            }
                        </Grid>
                        {lead.preferredContactMethodId == 1 &&
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        showToolbar={false}
                                        inputFormat="dd/MM/yyyy"
                                        mask="dd/MM/yyyy"
                                        label="Booked Call Date"
                                        value={lead.callDate}
                                        onChange={(newValue: any) => {
                                            setLead({
                                                ...lead,
                                                callDate: newValue as Date
                                            });
                                        }}
                                        renderInput={(params: any) => <TextField fullWidth
                                            margin="dense" variant="outlined" {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        }
                        {lead.preferredContactMethodId == 1 &&
                            <Grid item xs={6}>
                                <TextValidator
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    label="Booked Call Time"
                                    name="callTime"
                                    value={lead.callTime}
                                    onChange={onChange}
                                />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <TextValidator
                                variant="outlined"
                                multiline
                                rows={4}
                                margin="dense"
                                fullWidth
                                label="Notes"
                                name="notes"
                                value={lead.notes}
                                onChange={onChange}
                                validators={['required']}
                                errorMessages={['Required']}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="secondary" disabled={submitting}>Cancel</Button>
                    <Button type="submit" color="primary" disabled={submitting}>
                        Submit
                        {submitting && <CircularProgress color='primary' size={24} className={classes.buttonProgress} />}
                    </Button>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
}