import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { CreateCampaignNewAccountViewModel, CreateCampaignViewModel } from '../ViewModels/CampaignViewModel';

import CampaignTypeDropdown from '../Dropdowns/CampaignTypeDropdown';
import authService from '../api-authorization/AuthorizeService';
import { Redirect } from 'react-router';
import CountryDropdown from '../Dropdowns/CountryDropdown';
import MuiPhoneNumber from 'material-ui-phone-number';
import RefIndustryExtendedViewModel from './ViewModels/RefIndustryExtendedViewModel';
import SaleHandoverQuestionsWizard from './SaleHandoverQuestionsWizard';
import SaleHandoverQuestionsViewModel from './ViewModels/SaleHandoverQuestionsViewModel';
import Autocomplete from '@mui/material/Autocomplete';
import CreateCampaignNewAccount from './CreateCampaignNewAccount';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
        },
    })
);

export default function CreateCampaign() {
    const classes = useStyles();

    const [campaign, setCampaign] = React.useState(new CreateCampaignViewModel());
    const [success, setSuccess] = React.useState(false);
    const [nameFree, setNameFree] = React.useState(true);
    const [serverError, setError] = React.useState('');
    const [clientIndustries, setClientIndustries] = React.useState<RefIndustryExtendedViewModel[]>([]);
    const [stringIndustries, setStringIndustries] = React.useState<string[]>([]);
    const [newIndustry, setNewIndustry] = React.useState<string>();
    const [saleHandoverQuestions, setSaleHandoverQuestions] = React.useState(new SaleHandoverQuestionsViewModel());
    const [editPh, setEditPh] = React.useState<boolean>(false);
    const [validPh, setValidPh] = React.useState<boolean>(false);
    const [newAccounts, setNewAccounts] = React.useState<CreateCampaignNewAccountViewModel[]>([]);
    const [accountId, setAccountId] = React.useState<number>(0);


    React.useEffect(() => {
        fetch('Campaign/GetClientIndustryList')
            .then(response => response.json())
            .then(data => {
                setClientIndustries(data.value);
            });
    }, []);

    React.useEffect(() => {
        setCampaign({
            ...campaign,
            saleHandoverQuestions: saleHandoverQuestions
        });
    }, [saleHandoverQuestions]);

    React.useEffect(() => {
        //const regexp = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/)
        //const test = regexp.test(campaign.contactPhone);
        let digitCount = 0;
        for (var i = 0; i < campaign.contactPhone.length; i++) {
            const isNum = campaign.contactPhone[i] >= '0' && campaign.contactPhone[i] <= '9';
            if (isNum) {
                digitCount++;
            }
        }
        if (digitCount >= 10) {
            setValidPh(true);
        }
        else {
            setValidPh(false);
        }
    }, [campaign.contactPhone]);

    React.useEffect(() => {
        let industryList = clientIndustries.map((options) => options.industry);
        setStringIndustries(industryList);
    }, [clientIndustries])

    React.useEffect(() => {
        let campaignWithIndustry = campaign;
        let industryID = clientIndustries.find(s => s.industry === newIndustry)?.id;
        if (industryID) {
            campaignWithIndustry.clientIndustry = industryID;
        }
        setCampaign(campaignWithIndustry);
    }, [newIndustry])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCampaign({
            ...campaign,
            [event.target.name]: event.target.value
        });
    }

    function onChangePh(value: any) {
        setCampaign({
            ...campaign,
            contactPhone: value
        })
        setEditPh(true);
    }

    const handleSaleHandoverChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSaleHandoverQuestions({
            ...saleHandoverQuestions,
            [event.target.name]: event.target.value
        });
    }

    const addAccount = () => {
        setNewAccounts([...newAccounts, new CreateCampaignNewAccountViewModel(accountId)]);
        setAccountId(accountId + 1);
    }
    const removeAccount = (id: number) => {
        setNewAccounts(newAccounts.filter(f => f.uid != id));
    }
    const editAccount = (replace: CreateCampaignNewAccountViewModel) => {
        let temp: CreateCampaignNewAccountViewModel[] = [];
        newAccounts.forEach(f =>
            f.uid === replace.uid ?
                temp.push(replace)
                :
                temp.push(f)
        )
        setNewAccounts([...temp]);
    }

    const submit = async () => {
        if (validPh) {
            const token = await authService.getAccessToken();
            fetch(`Campaign/CheckCampaignName?name=${campaign.campaignName}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            })
                .then(response => response.json())
                .then(data => {
                    setNameFree(data.value);
                    let vm = campaign;
                    vm.accounts = newAccounts
                    if (data.value) {
                        fetch('Campaign/CreateCampaign', {
                            method: 'POST',
                            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
                            body: JSON.stringify(vm)
                        })
                            .then(response => response.text())
                            .then(data => {
                                if (data === 'Ok') {
                                    setSuccess(true);
                                } else {
                                    setError('A server error has occurred, please try again.');
                                }
                            })
                            .catch(error => {
                                console.log(error);
                                setError('A server error has occurred, please try again.');
                            });
                    }
                })
                .catch(error => {
                    console.log('Check name error: ' + error);
                });
        }
        else {
            setEditPh(true);
        }
    }

    return (
        <React.Fragment>
            {success && <Redirect to='/Clients' />}
            <ValidatorForm onSubmit={submit}>
                <Paper className={classes.paper}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h2">Create Client</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" color="error">{serverError}</Typography>
                        </Grid>
                        {
                            !nameFree &&
                            <Grid item xs={12}>
                                <Typography variant="body1" color="error">Client already exists with this name</Typography>
                            </Grid>
                        }
                        <Grid item xs={3}>
                            <TextValidator
                                label="Client Name"
                                name="campaignName"
                                variant="outlined"
                                fullWidth
                                value={campaign.campaignName}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextValidator
                                label="Contact Name"
                                name="contactName"
                                variant="outlined"
                                fullWidth
                                value={campaign.contactName}
                                validators={['required']}
                                errorMessages={['This field is required']}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextValidator
                                label="Contact Email"
                                name="contactEmail"
                                variant="outlined"
                                type="email"
                                fullWidth
                                value={campaign.contactEmail}
                                validators={['required', 'isEmail']}
                                errorMessages={['This field is required', 'Invalid email format']}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <MuiPhoneNumber
                                variant="outlined"
                                fullWidth
                                defaultCountry={'gb'}
                                countryCodeEditable={false}
                                disableAreaCodes={true}
                                label='Contact Phone'
                                value={campaign.contactPhone}
                                onChange={onChangePh}
                                error={!validPh && editPh}
                                helperText={(!validPh && campaign.contactPhone.length > 0) && "Invalid phone number"}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <CampaignTypeDropdown campaignTypeId={campaign.campaignTypeId} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={3}>
                            <CountryDropdown id={campaign.ref_CountryId} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={3}>
                            <Autocomplete
                                onChange={(event: any, newValue: string) => {
                                    setNewIndustry(newValue);
                                }}
                                value={newIndustry}
                                options={stringIndustries}
                                disableClearable
                                autoHighlight
                                autoComplete
                                autoSelect
                                fullWidth
                                renderInput={(params) =>
                                    <TextValidator {...params} label="Client Industry"
                                        variant="outlined"
                                        name="client Industry"
                                        value={newIndustry}
                                        validators={['required']}
                                        errorMessages={['This field is required']} />}
                            />
                        </Grid>
                        {newAccounts.length > 0 &&
                            newAccounts.map((account) =>
                                <Grid item xs={12} key={account.uid}>
                                    <Paper className={classes.paper}>
                                        <Grid container spacing={1}>
                                            <CreateCampaignNewAccount
                                                newAccount={account}
                                                editAccount={editAccount}
                                                removeAccount={removeAccount} />
                                        </Grid>
                                    </Paper>
                                </Grid>
                            )}
                        <Grid item xs={12} >
                            <Button
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                onClick={addAccount}
                            >Add Account</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <SaleHandoverQuestionsWizard
                                saleHandoverQuestions={saleHandoverQuestions}
                                handleChange={handleSaleHandoverChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="secondary" type="submit">Create</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </ValidatorForm>
        </React.Fragment>
    );
}