import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import red from '@mui/material/colors/red';
import green from '@mui/material/colors/green';
import Grid from '@mui/material/Grid';
import authService from '../../api-authorization/AuthorizeService';
import { SetTargetsViewModel, WeeklyMetricsViewModel } from '../../ViewModels/ActivityDateViewModel';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Save from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            marginBottom: theme.spacing(2),

        },
        red: {
            color: red[400],
            fontWeight: "bold"
        },
        green: {
            color: green[400]
        }
    })
);

interface ISetTargetsProps {
    accountId: number;
    setMetrics: React.Dispatch<React.SetStateAction<(WeeklyMetricsViewModel)>>;
    setMetricsLoaded: (loaded: boolean) => void;
}

export default function SetTargets(props: ISetTargetsProps) {
    const classes = useStyles();
    const [targets, setTargets] = React.useState(new SetTargetsViewModel());

    React.useEffect(() => {
        setAccountId()
    }, [props.accountId]);

    const setAccountId = () => {
        setTargets({
            ...targets,
            accountId: props.accountId
        });
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTargets({
            ...targets,
            [event.target.name]: event.target.valueAsNumber
        });
    }
    const handleSet = async () => {
        const token = await authService.getAccessToken();

        fetch('Account/SetTargets', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(targets)
        })
            .then(response => response.text())
            .then(data => {
                props.setMetricsLoaded(false);
                fetch(`Account/GetWeeklyMetric?accountId=${props.accountId}`, {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                    .then(response => response.json())
                    .then(data => {
                        props.setMetrics(data.value)
                        setTargets(new SetTargetsViewModel());
                        props.setMetricsLoaded(true);
                    })
                    .catch(error => {
                        console.log('Get weekly metrics error: ' + error);
                        props.setMetricsLoaded(true);
                    });
            })
            .catch(error => {

            });
    }

    return (
        <React.Fragment>
            <div className={classes.paper}>
                <ValidatorForm onSubmit={handleSet}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Set Targets</Typography>
                        </Grid>
                        <Grid item xs={3.6}>
                            <TextValidator
                                fullWidth
                                size="small"
                                variant="outlined"
                                label="Connections"
                                type="number"
                                name="targetConnections"
                                value={targets.targetConnections}
                                onChange={onChange}
                                validators={['required', 'isNumber', 'minNumber: 1']}
                                errorMessages={['Required', 'Must be a number greater than 0', 'Must be a number greater than 0']}
                            />
                        </Grid>
                        <Grid item xs={3.6}>
                            <TextValidator
                                fullWidth
                                size="small"
                                variant="outlined"
                                label="Messages + Inmails"
                                type="number"
                                name="targetMessagesAndInmails"
                                value={targets.targetMessagesAndInmails}
                                onChange={onChange}
                                validators={['required', 'isNumber', 'minNumber: 1']}
                                errorMessages={['Required', 'Must be a number greater than 0', 'Must be a number greater than 0']}
                            />
                        </Grid>
                        <Grid item xs={3.6}>
                            <TextValidator
                                fullWidth
                                size="small"
                                variant="outlined"
                                label="Follow Ups"
                                type="number"
                                name="targetFollowUps"
                                value={targets.targetFollowUps}
                                onChange={onChange}
                                validators={['required', 'isNumber', 'minNumber: 1']}
                                errorMessages={['Required', 'Must be a number greater than 0', 'Must be a number greater than 0']}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton size="small"  type="submit" color="primary"><Save /></IconButton>
                        </Grid>
                    </Grid>
                </ValidatorForm >
            </div>
        </React.Fragment>
    );
}