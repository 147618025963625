import * as React from 'react';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useParams, Redirect } from 'react-router';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
        },
    }),
);

interface RouteParams {
    userId: string;
    code: string;
}

export default function RegisterConfirmation() {
    const classes = useStyles();
    const { userId, code } = useParams<RouteParams>();

    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [redirect, setRedirect] = React.useState(false);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    }

    const onConfirmChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(event.target.value);
    }

    const createUser = async () => {
        fetch('User/SetPassword', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify({ userId: userId, code: code, password: password })
        })
            .then(response => response.json())
            .then(data => {
                if (data.value === 'Ok') {
                    setRedirect(true);
                }
            });
    }

    ValidatorForm.addValidationRule('isPasswordMatch', value => {
        if (value !== password) {
            return false;
        }
        return true;
    });

    ValidatorForm.addValidationRule('hasUppercase', value => {
        if (/^(?=.*[A-Z]).{1,}$/.test(value)) {
            return true;
        }
        return false;
    });

    ValidatorForm.addValidationRule('hasLowercase', value => {
        if (/^(?=.*[a-z]).{1,}$/.test(value)) {
            return true;
        }
        return false;
    });

    ValidatorForm.addValidationRule('hasNumber', value => {
        if (/^(?=.*[0-9]).{1,}$/.test(value)) {
            return true;
        }
        return false;
    });

    ValidatorForm.addValidationRule('hasSpecialChar', value => {
        if (/^(?=.*[\W]).{1,}$/.test(value)) {
            return true;
        }
        return false;
    });

    ValidatorForm.addValidationRule('noWhitespace', value => {
        if (/^\S*$/.test(value)) {
            return true;
        }
        return false;
    });

    return (
        <React.Fragment>
            {
                redirect ? <Redirect to="/" />
                    :
                    <ValidatorForm onSubmit={createUser}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Paper className={classes.paper}>
                                    <Typography variant="h2">Create Password</Typography>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <TextValidator
                                                fullWidth
                                                variant="outlined"
                                                margin="normal"
                                                label="Password"
                                                name="password"
                                                value={password}
                                                type="password"
                                                validators={['required', 'minStringLength:  6', 'noWhitespace', 'hasUppercase', 'hasLowercase', 'hasNumber', 'hasSpecialChar']}
                                                errorMessages={['This field is required', 'Must be at least 6 characters', 'No whitespace', 'Must contain at least 1 uppercase letter', 'Must contain at least 1 lowercase letter', 'Must contain at least 1 number', 'Must contain at least 1 special character']}
                                                onChange={onChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextValidator
                                                fullWidth
                                                variant="outlined"
                                                margin="normal"
                                                label="Confirm Password"
                                                name="confirmPassword"
                                                value={confirmPassword}
                                                type="password"
                                                validators={['isPasswordMatch']}
                                                errorMessages={["Password doesn't match"]}
                                                onChange={onConfirmChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button fullWidth variant="contained" color="primary" type="submit">Confirm</Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
            }
        </React.Fragment>
    );
}