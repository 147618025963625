import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DropdownViewModel from '../ViewModels/DropdownViewModel';
import authService from '../api-authorization/AuthorizeService';
import TeamViewModel from './TeamViewModel';
import TeamTableRow from './TeamTableRow';

interface ITeamTableProps {
    teams: TeamViewModel[];
    roles: string | string[];
    refresh: () => void;
    users: DropdownViewModel[];
}

export default function TeamTable(props: ITeamTableProps) {
    const [users, setUsers] = React.useState<DropdownViewModel[]>([]);
    React.useEffect(() => { getData(); }, []);

    const items = props.teams.map((item, index) =>
        <TeamTableRow key={index} teamLeaders={users} users={ users} team={item} roles={props.roles} refresh={props.refresh} />
    );

    const getData = async () => {
        const token = await authService.getAccessToken();

        fetch('User/GetTeamLeaders', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        })
            .then(response => response.json())
            .then(data => {
                setUsers(data.value);
            })
            .catch(error => {
                console.log('Get team leaders error: ' + error);
            });
    }

    return (
        <Table aria-label="campaign list">
            <TableHead>
                <TableRow>
                    <TableCell>Team</TableCell>
                    <TableCell />
                    <TableCell />
                </TableRow>
            </TableHead>
            <TableBody>
                {items}
            </TableBody>
        </Table>
    );
}