import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { MessageViewModel } from '../../ViewModels/LinkedInAccountViewModel';
import authService from '../../api-authorization/AuthorizeService';
import Message from './Message';
import CreateMessage from './CreateMessage';
import Grow from '@mui/material/Grow';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginBottom: theme.spacing(2)
        }
    })
);

interface IMessageProps {
    accountId: number;
}

export default function Messages(props: IMessageProps) {
    const classes = useStyles();
    const [messages, setMessages] = React.useState<MessageViewModel[]>([]);
    const [loaded, setLoaded] = React.useState(true);

    const getData = React.useCallback(async () => {
        setLoaded(false);
        const token = await authService.getAccessToken();

        fetch(`Account/GetMessages?accountId=${props.accountId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setMessages(data.value);
                setLoaded(true);
            })
            .catch(error => {
                console.log('Message list error: ' + error);
                setLoaded(true);
            });
    }, [props.accountId]);
    React.useEffect(() => { getData(); }, [getData]);

    const items = messages.map(item => <Message key={item.id} message={item} refresh={getData} />);

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <Typography variant="h5">Client Messages</Typography>
            </Grid>
            <Grow in={loaded}
                {...(loaded ? { timeout: 1000 } : {})}>
                <Grid item xs={12}>
                    {items}
                    <CreateMessage accountId={props.accountId} refresh={getData} />
                </Grid>
            </Grow>
        </Grid>
    );
}