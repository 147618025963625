import { createContext, useEffect, useState } from 'react';

type ContextType = {
    checkHasPermission: (permission?: string) => boolean;
}

type PermissionProviderProps = { children: any }

export const PermissionsContext = createContext<ContextType>({
    checkHasPermission: () => { throw Error('Cannot use context without provider'); }
})

export default function PermissionsProvider(props: PermissionProviderProps) {
    const [permissions, setPermissions] = useState<string[] | null>(null);

    useEffect(() => {
        getUserPermissions();
    }, []);

    const getUserPermissions = () => {
        fetch('Permission/GetUserPermissions')
            .then(response => response.json())
            .then(data => {
                setPermissions(data.value);
            });
    }

    const checkHasPermission = (permission?: string) => {
        if (permissions != null) {
            if (permission == undefined) {
                return true;
            }
            else {
                return permissions.includes(permission) || permissions.includes('SuperAdmin');
            }
        }
        else {
            return false;
        }
    }

    return (
        <PermissionsContext.Provider value={{ checkHasPermission }}>
            {props.children}
        </PermissionsContext.Provider>
    )
}
