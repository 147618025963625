import * as React from 'react';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useLocation } from 'react-router';
import authService from '../../api-authorization/AuthorizeService';
import LinearProgress from '@mui/material/LinearProgress';
import Skeleton from '@mui/material/Skeleton';
import Edit from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import CampaignViewModel from '../../ViewModels/CampaignViewModel';
import Notes from './Notes';
import CampaignInfo from './CampaignInfo';
import AccountTable from './AccountTable';
import MultiFileInput from './MultiFileInput';
import FileDataViewModel from '../../ViewModels/FileDataViewModel';
import { CreateFileViewModel } from '../../ViewModels/CreateFileViewModel';
import { UtilityMethods } from './UtilityMethods';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import TeamLeaderDialog from '../TeamLeaderDialog';
import DropdownViewModel from '../../ViewModels/DropdownViewModel';
import Messages from './Messages';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import OpManagerDialog from '../OpManagerDialog';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import CopywriterDialog from '../CopywriterDialog';
import { PermissionCheck } from '../../Contexts/PermissionCheck';
import ClientDirectorDialog from '../ClientDirectorDialog';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        showButton: {
            padding: 8,
        },
        bottomSpacing: {
            marginBottom: theme.spacing(2)
        },
        password: {
            paddingBottom: 0
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        }
    })
);

interface LocationState {
    campaignId: number;
}

export default function Campaign() {
    const classes = useStyles();
    const location = useLocation<LocationState>();
    const { campaignId } = location.state || {
        CampaignId: 0
    };
    const [loaded, setLoaded] = React.useState(false);
    const [user, setUser] = React.useState<any>(null);
    const [openUploadDialog, setUploadOpen] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [error, setError] = React.useState('');
    const [campaign, setCampaign] = React.useState(new CampaignViewModel());
    const [fileData, setFileData] = React.useState<any>(null);
    const [files, setFiles] = React.useState<any>(null);
    const [fileList, setFileList] = React.useState<string[]>([]);
    const [filesUpdating, setFilesUpdating] = React.useState(false);
    const [openExecDialog, setDialogOpen] = React.useState(false);
    const [openOpManagerDialog, setOpManagerDialogOpen] = React.useState(false);
    const [openDirectorDialog, setDirectorDialogOpen] = React.useState(false);
    const [copywriterDialogOpen, setCopywriterDialogOpen] = React.useState(false);
    const [users, setUsers] = React.useState<DropdownViewModel[]>([]);
    const [clientName, setClientName] = React.useState<string>("");
    const [isBoostOrVelocity, setIsBoostOrVelocity] = React.useState<boolean>(false);
    React.useEffect(() => {
        setClientName(campaign.campaignName)
        for (var i = 0; i < campaign.accounts.length; i++) {
            if (campaign.accounts[i].packageName.indexOf("Boost") !== -1 || campaign.accounts[i].packageName.indexOf("Velocity") !== -1) {
                setIsBoostOrVelocity(true);
            }
        }
    }, [campaign])
    React.useEffect(() => {
        getFiles();
        getData();
    }, [campaignId]);
    const getData = async () => {
        setLoaded(false);
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        setUser(user);

        fetch(`Campaign/GetCampaignWithAccounts?campaignId=${campaignId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setCampaign(data.value);
                setLoaded(true);
            })
            .catch(error => {
                console.log('Campaign error: ' + error);
                setLoaded(true);
            });
        fetch('User/GetTeamLeaders', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        })
            .then(response => response.json())
            .then(data => {
                setUsers(data.value);
            })
            .catch(error => {
                console.log('Get team leaders error: ' + error);
            });
    }
    const getFiles = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        setUser(user);

        fetch(`Campaign/GetFileList?clientId=${campaignId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setFileList(data.value);
                setFilesUpdating(false);
            })
            .catch(error => {
                console.log('File error: ' + error);
            });
    }
    const discoveryButton = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((props, ref) => (
        <RouterLink
            ref={ref}
            to={{
                pathname: '/DataCapture/ClientDiscoveryForm',
                state: { campaignId }
            }}
            {...props} />
    ));

    const submit = (fileData: FileDataViewModel[], file: File[]) => {
        let formdata: FormData = new FormData();
        setSubmitting(true);
        setError('');
        var now = new Date();
        setFilesUpdating(true);
        var files: CreateFileViewModel[] = [];
        for (var i = 0; i < file.length; i++) {
            let createFileViewModel = new CreateFileViewModel;
            createFileViewModel.file = file[i];
            createFileViewModel.name = fileData[i].fileName;
            createFileViewModel.dateAdded = now;
            createFileViewModel.campaignId = campaignId;
            files.push(createFileViewModel);
        }
        formdata = UtilityMethods.convertModelToFormData(files, formdata);

        fetch('Campaign/UploadFiles', {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            body: formdata
        })
            .then((response: Response) => response.json())
            .then((data: any) => {
                setSubmitting(false);
                setUploadOpen(false);
                getFiles();
            })
            .catch(error => {
                setSubmitting(false);
                setError('An error occured');
            });
    }
    const removeFile = (fileData2: FileDataViewModel,) => {
        let f = files;
        let filesData = fileData;
        const dataindex = filesData.indexOf(fileData2);
        if (dataindex > -1) {
            filesData.splice(dataindex, 1);
            f.splice(dataindex, 1);
        }
        setFiles(f);
        setFileData(filesData);
    }
    const openCopywriterDialog = () => {
        setCopywriterDialogOpen(true);
    }
    const openUploaderDialog = () => {
        setUploadOpen(true);
    }
    const openTeamLeaderDialog = () => {
        setDialogOpen(true);
    }
    const openDirectorDialogButton = () => {
        setDirectorDialogOpen(true);
    }
    const openOpManagerDialogButton = () => {
        setOpManagerDialogOpen(true);
    }
    const onClose = (refresh: boolean) => {
        if (!submitting) {
            setUploadOpen(false);
            setDialogOpen(false);
            setOpManagerDialogOpen(false);
            setDirectorDialogOpen(false);
            setCopywriterDialogOpen(false);
            if (refresh) {
                getData();
            }
        }
    }
    const deleteFile = async (m: string) => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        setUser(user);
        setFilesUpdating(true);
        fetch(`Campaign/DeleteFile?fileId=${m}&clientId=${campaignId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                getFiles();
            })
            .catch(error => {
                getFiles();
                console.log('File error: ' + error);
            });
    }

    const filesList = fileList.map((m, index) =>
        <Grid key={'file' + index} container alignItems="center">
            <Grid item xs={8} style={{
                display: "block",
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
            }}>
                <Tooltip title={m}><Typography variant="body1">{m}</Typography></Tooltip>
            </Grid>
            <Grid item xs={4}>
                <Grid container>
                    <Grid item xs={4}>
                        <a href={`Campaign/GetFilePreview?fileId=${encodeURIComponent(m)}&clientId=${campaignId}`} target="_blank">
                            <IconButton
                                color="primary"
                                aria-label="view"
                                size="small"
                                disabled={
                                    !m.endsWith(".jpg") &&
                                    !m.endsWith(".pdf") &&
                                    !m.endsWith(".weba") &&
                                    !m.endsWith(".webm") &&
                                    !m.endsWith(".webp") &&
                                    !m.endsWith(".wav") &&
                                    !m.endsWith(".txt") &&
                                    !m.endsWith(".tif") &&
                                    !m.endsWith(".tiff") &&
                                    !m.endsWith(".png") &&
                                    !m.endsWith(".mpeg") &&
                                    !m.endsWith(".mp3") &&
                                    !m.endsWith(".jpeg") &&
                                    !m.endsWith(".gif") &&
                                    !m.endsWith(".docx") &&
                                    !m.endsWith(".doc") &&
                                    !m.endsWith(".avi") &&
                                    !m.endsWith(".bmp") &&
                                    !m.endsWith(".svg")
                                }
                            >
                                <VisibilityIcon />
                            </IconButton>
                        </a>
                    </Grid>
                    <Grid item xs={4}>
                        <a href={`Campaign/GetFileFromBlob?fileId=${encodeURIComponent(m)}&clientId=${campaignId}`} target="_blank">
                            <IconButton
                                color="primary"
                                aria-label="download"
                                component="span"
                                size="small"
                            >
                                <CloudDownloadIcon />
                            </IconButton>
                        </a>
                    </Grid>
                    <Grid item xs={4}>
                        <IconButton color="primary" aria-label="delete" component="span" size="small" onClick={() => deleteFile(m)}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );

    return (
        <Grid container spacing={2}>
            {!loaded && <LinearProgress />}
            <Grid item xs={12} lg={12} style={{ marginTop: "10px" }}>
                <Typography variant="h2">{loaded ? clientName : <Skeleton />}</Typography>
            </Grid>
            <Grid item xs={12} lg={3}>
                <CampaignInfo campaignId={campaignId} clientName={clientName} setClientName={setClientName} />
                <Paper className={classes.paper}>
                    <Typography variant="h6">Client Manager</Typography>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Typography>
                                {loaded ? ((campaign.teamLeader !== null && campaign.teamLeader.length > 0 ?
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <Avatar src={`https://storagemiy3budetxmak.blob.core.windows.net/clistraightin/${campaign.teamLeaderId}/Avatar`}>
                                                {campaign.teamLeader.substring(0, 2)}
                                            </Avatar>
                                        </Grid>
                                        <Grid item>
                                            {campaign.teamLeader}
                                        </Grid>
                                    </Grid> : 'None Assigned'))
                                    : <Skeleton />
                                }
                            </Typography>
                        </Grid>
                        <PermissionCheck permission="Actions.UpdateOpManager">
                            <Grid item>
                                <IconButton size="small" onClick={openTeamLeaderDialog} style={{ marginLeft: 8 }}>
                                    <Edit color="secondary" />
                                </IconButton>
                            </Grid>
                        </PermissionCheck>
                    </Grid>
                </Paper>
                <Paper className={classes.paper}>
                    <Typography variant="h6">Client Director</Typography>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Typography>
                                {loaded ? ((campaign.clientDirector !== null && campaign.clientDirector.length > 0 ?
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <Avatar src={`https://storagemiy3budetxmak.blob.core.windows.net/clistraightin/${campaign.clientDirectorId}/Avatar`}>
                                            </Avatar>
                                        </Grid>
                                        <Grid item>
                                            {campaign.clientDirector}
                                        </Grid>
                                    </Grid> : 'None Assigned'))
                                    : <Skeleton />
                                }
                            </Typography>
                        </Grid>
                        <PermissionCheck permission="Actions.UpdateOpManager">
                            <Grid item>
                                <IconButton size="small" onClick={openDirectorDialogButton} style={{ marginLeft: 8 }}>
                                    <Edit color="secondary" />
                                </IconButton>
                            </Grid>
                        </PermissionCheck>
                    </Grid>
                </Paper>
                <Paper className={classes.paper}>
                    <Typography variant="h6">Operations Manager</Typography>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Typography>
                                {loaded ? ((campaign.opManager !== null ?
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <Avatar src={`https://storagemiy3budetxmak.blob.core.windows.net/clistraightin/${campaign.opManagerId}/Avatar`}>
                                                {campaign.teamLeader.substring(0, 2)}
                                            </Avatar>
                                        </Grid>
                                        <Grid item>
                                            {campaign.opManager}
                                        </Grid>
                                    </Grid> : 'None Assigned'))
                                    : <Skeleton />
                                }
                            </Typography>
                        </Grid>
                        <PermissionCheck permission="Actions.UpdateOpManager">
                            <Grid item>
                                <IconButton size="small" onClick={openOpManagerDialogButton} style={{ marginLeft: 8 }}>
                                    <Edit color="secondary" />
                                </IconButton>
                            </Grid>
                        </PermissionCheck>
                    </Grid>
                </Paper>
                {isBoostOrVelocity &&
                    <Paper className={classes.paper}>
                        <Typography variant="h6">Copywriter</Typography>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Typography>
                                    {loaded ? ((campaign.copywriter !== null && campaign.copywriter.length > 0 ?
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item>
                                                <Avatar src={`https://storagemiy3budetxmak.blob.core.windows.net/clistraightin/${campaign.copywriterId}/Avatar`}>
                                                    {campaign.copywriter.substring(0, 2)}
                                                </Avatar>
                                            </Grid>
                                            <Grid item>
                                                {campaign.copywriter}
                                            </Grid>
                                        </Grid> : 'None Assigned'))
                                        : <Skeleton />
                                    }
                                </Typography>
                            </Grid>
                            <Grid item>
                                {(user?.role !== "Executive" && user?.role !== "Creator") &&
                                    <IconButton size="small" onClick={openCopywriterDialog} style={{ marginLeft: 8 }}>
                                        <Edit color="secondary" />
                                    </IconButton>
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                }
            </Grid>
            <Grid item xs={12} lg={6}>
                <Paper className={classes.paper}>
                    <AccountTable user={user} accounts={campaign.accounts} />
                </Paper>
                <Notes clientId={campaign.id} />
            </Grid>
            <Grid item xs={12} lg={3}>
                <Paper className={classes.paper}>
                    {loaded ?
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Files</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {filesUpdating ? <CircularProgress style={{ marginLeft: '50%' }} /> : filesList}
                            </Grid>
                            <Grid item xs={12}>
                                <Button color="secondary" variant="outlined" fullWidth size="small" onClick={openUploaderDialog}>Upload</Button>
                            </Grid>
                        </Grid>
                        : <Skeleton height={100} />
                    }
                </Paper>
                {loaded ?
                    <Messages campaignId={campaignId} />
                    : <Skeleton height={100} />
                }
                {(campaign.clientDiscoveryComplete == false || (user?.role !== "Executive" && user?.role !== "Creator")) &&
                    <Button variant="outlined" color="secondary" fullWidth component={discoveryButton}>Client Discovery Form</Button>
                }

            </Grid>
            <Dialog open={openUploadDialog} aria-labelledby="form-dialog-title">

                <DialogTitle id="form-dialog-title">Upload Files</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {error}
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <MultiFileInput
                                fileData={fileData}
                                files={files}
                                addFile={submit}
                                removeFile={removeFile}
                                submitting={submitting}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClose(false)} fullWidth color="secondary" disabled={submitting}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <TeamLeaderDialog users={users} campaignId={campaignId} open={openExecDialog} close={onClose} />
            <ClientDirectorDialog users={users} campaignId={campaignId} open={openDirectorDialog} close={onClose} />
            <OpManagerDialog users={users} campaignId={campaignId} open={openOpManagerDialog} close={onClose} />
            <CopywriterDialog users={users} campaignId={campaignId} open={copywriterDialogOpen} close={onClose} />
        </Grid>
    );
}