import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { CreateNoteViewModel } from '../../ViewModels/LinkedInAccountViewModel';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import authService from '../../api-authorization/AuthorizeService';
import MenuItem from '@mui/material/MenuItem';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        }
    }),
);

interface ICreateProps {
    clientId: number;
    accountId: number;
    open: boolean;
    close: (refresh: boolean) => void;
    from: string;
    to: string;
}

export default function CreateStatusNote(props: ICreateProps) {
    const classes = useStyles();
    const [note, setNote] = React.useState(new CreateNoteViewModel(props.accountId));
    const [saving, setSaving] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [serverError, setError] = React.useState('');
    const [reasons, setReasons] = React.useState([]);

    React.useEffect(() => {

        getReasons();
    }, [props.to]);
    const getReasons = async () => {
        const token = await authService.getAccessToken();

        fetch(`Campaign/GetCommonReasons?from=${props.from}&to=${props.to}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => response.json())
            .then(data => {
                setReasons(data.value);
                setLoading(false);
            })
            .catch(error => {
            });
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNote({
            ...note,
            [event.target.name]: event.target.value
        });
    }


    const onClose = () => {
        if (!saving) {
            setNote(new CreateNoteViewModel(props.accountId));
            props.close(false);
        }
    }

    const submit = async () => {
        setSaving(true);
        const token = await authService.getAccessToken();

        const newNote = note;
        note.id = props.accountId;
        note.statusTo = props.to;
        fetch('Campaign/CreateStatusNote', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(newNote)
        })
            .then(response => response.text())
            .then(data => {
                setNote(new CreateNoteViewModel(props.accountId));
                fetch(`Account/UpdateStatus?accountId=${props.accountId}&status=${props.to}`, {
                    method: 'POST',
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                })
                    .then(response => response.text())
                    .then(data => {
                        setSaving(false);
                        props.close(true);
                    })
                    .catch(error => {
                        setSaving(false);
                    });
            })
            .catch(error => {
                setSaving(false);
                setError('A server error has occurred, please try again.');
            });


    }
    const commonReasons = reasons.map(m => <MenuItem key={m} value={m}>{m}</MenuItem>);
    return (

        <Dialog open={props.open} aria-labelledby="form-dialog-title" fullWidth>
            <DialogTitle>
                <Typography className={classes.heading}>Add Note</Typography>
            </DialogTitle>
            <ValidatorForm onSubmit={submit}>
                {!loading &&
                    <React.Fragment>
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="body1" color="error">{serverError}</Typography>
                                </Grid>
                                {
                                    reasons.length > 0 &&
                                    <Grid item xs={12}>
                                        <SelectValidator
                                            label="Common Reasons"
                                            name="commonReason"
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            value={note.commonReason}
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                            onChange={handleChange}
                                        >
                                            {commonReasons}
                                        </SelectValidator>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    {
                                        reasons.length > 0 ?
                                            <TextValidator
                                                label="Reason for status change"
                                                name="content"
                                                variant="outlined"
                                                margin="dense"
                                                fullWidth
                                                multiline
                                                rows={4}
                                                value={note.content}
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                                onChange={handleChange}
                                            />
                                            :
                                            <TextValidator
                                                label="Reason for status change"
                                                name="content"
                                                variant="outlined"
                                                margin="dense"
                                                fullWidth
                                                multiline
                                                rows={4}
                                                value={note.content}
                                                onChange={handleChange}
                                            />
                                    }
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose} color="secondary" disabled={saving}>Cancel</Button>
                            <Button type="submit" color="primary" disabled={saving}>
                                Save
                                {saving && <CircularProgress color='secondary' size={24} className={classes.buttonProgress} />}
                            </Button>
                        </DialogActions>
                    </React.Fragment>
                }
            </ValidatorForm>
        </Dialog>
    );
}