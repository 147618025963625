import * as React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BusinessIcon from '@mui/icons-material/Business';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CssBaseline from '@mui/material/CssBaseline';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import authService from './api-authorization/AuthorizeService';
import { LoginMenu } from './api-authorization/LoginMenu';
import GroupIcon from '@mui/icons-material/Group';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import './NavMenu.css';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Notifications from '../components/Notifications/Notifications';
import { PermissionCheck } from './Contexts/PermissionCheck';
import ApprovalRequests from './ApprovalRequests/ApprovalRequests';
import ApprovalIcon from '@mui/icons-material/Approval';

const drawerWidth = 200;
const miniDrawerWidth = 75;
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        titleImage: {
            flexGrow: 1
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: theme.palette.primary.main
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
            backgroundColor: theme.palette.primary.main,

        },
        miniDrawer: {
            width: miniDrawerWidth,
            flexShrink: 0,
        },
        miniDrawerPaper: {
            width: miniDrawerWidth,
            backgroundColor: theme.palette.primary.main
        },
        drawerContainer: {
            overflow: 'auto',
            color: '#ffffff'
        },
        drawerToggleMin: {
            width: 10,
            position: "fixed",
            top: "50%",
            left: 62,
            zIndex: theme.zIndex.drawer + 1
        },
        drawerToggleMax: {
            width: 10,
            position: "fixed",
            top: "50%",
            left: 227,
            zIndex: theme.zIndex.drawer + 1
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        report: {
            height: "23vh",
            margin: "0",
            width: "190px"
        },
        icon: {
            color: '#ffffff'
        },
        padding: {
            paddingLeft: theme.spacing(2),
        },
        avatar: {
            color: theme.palette.secondary.contrastText,
            backgroundColor: theme.palette.secondary.main
        },
        small: {
            width: theme.spacing(6),
            height: theme.spacing(6),
            color: theme.palette.secondary.contrastText,
            backgroundColor: theme.palette.secondary.main,
        },

    }),
);

interface ListItemLinkProps {
    icon?: React.ReactElement;
    primary: string;
    secondary?: any;
    to: string;
    show: boolean;
}

function ListItemLink(props: ListItemLinkProps) {
    const { icon, primary, secondary, to, show } = props;

    const renderLink = React.useMemo(
        () =>
            React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
                <RouterLink to={to} ref={ref} {...itemProps} />
            )),
        [to],
    );

    return (
        <li >
            <ListItem button component={renderLink}>
                {icon ? <ListItemIcon >{icon}</ListItemIcon> : null}
                {show &&
                    <ListItemText primary={primary} secondary={secondary} />
                }
            </ListItem>
        </li>
    );
}

export default function NavMenu() {
    const classes = useStyles();
    const [token, setToken] = React.useState('');
    const [show, setShow] = React.useState(true);
    const [id, setId] = React.useState('');
    const [userName, setUserName] = React.useState('');
    const [name, setName] = React.useState('');
    const [team, setTeam] = React.useState(0);
    React.useEffect(() => {
        getRole();
    }, []);

    const idFilter = {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
            table: "AccountSnapShots",
            column: "MainExecutiveId"
        },
        operator: "In",
        values: [id],
        filterType: models.FilterType.Basic,
        requireSingleSelection: true
    }
    const teamFilter = {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
            table: "AccountSnapShots",
            column: "TeamId"
        },
        operator: "In",
        values: [team],
        filterType: models.FilterType.Basic,
        requireSingleSelection: true
    }
    const getRole = async () => {
        const user = await authService.getUser();
        if (user !== null) {
            setId(user.sub);
            setUserName(user.name);
            const token = await authService.getAccessToken();

            fetch(`Report/GetGenericPBIToken`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(response => response.json())
                .then(data => {
                    setToken(data.value.embedToken);
                });

            fetch(`Account/GetTeam`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(response => response.json())
                .then(data => {
                    setTeam(data.value);
                });

            fetch(`Account/GetName`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(response => response.json())
                .then(data => {
                    setName(data.value);
                });
        }
        setShow(true);
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <div className={classes.titleImage}>
                        <img alt="Impact" src="IMPACTLOGO.png" height="40" />
                    </div>
                    <Notifications />
                    <ApprovalRequests />
                    <List style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                        <ListItemLink to="/Profile" primary={userName} secondary={<Typography variant="caption">{name}</Typography>} icon={<Avatar src={`https://storagemiy3budetxmak.blob.core.windows.net/clistraightin/${id}/Avatar`} alt={name} className={classes.small}>{name[0]}</Avatar>} show={show} />
                    </List>
                    <LoginMenu />
                </Toolbar>
            </AppBar>
            <PermissionCheck>
                <Drawer
                    className={show ? classes.drawer : classes.miniDrawer}
                    variant="permanent"
                    classes={{
                        paper: show ? classes.drawerPaper : classes.miniDrawerPaper,

                    }}
                >
                    <Toolbar />
                    <div className={classes.drawerContainer}>
                        <List>
                            <PermissionCheck permission="Pages.Dashboard"><ListItemLink to="/Dashboard" primary="Dashboard" icon={<BusinessIcon className={classes.icon} />} show={show} /></PermissionCheck>
                            <PermissionCheck permission="Pages.Holding"><ListItemLink to="/Holding" primary="Holding" icon={<FolderOpenIcon className={classes.icon} />} show={show} /></PermissionCheck>
                            <PermissionCheck permission="Pages.Onboarding"><ListItemLink to="/Onboarding" primary="Onboarding" icon={<FolderOpenIcon className={classes.icon} />} show={show} /></PermissionCheck>
                            <PermissionCheck permission="Pages.Clients"><ListItemLink to="/Clients" primary="Clients" icon={<BusinessIcon className={classes.icon} />} show={show} /></PermissionCheck>
                            <PermissionCheck permission="Pages.Accounts"><ListItemLink to="/Accounts" primary="Accounts" icon={<ContactMailIcon className={classes.icon} />} show={show} /></PermissionCheck>
                            <PermissionCheck><ListItemLink to="/Reports" primary="Reports" icon={<AssessmentIcon className={classes.icon} />} show={show} /></PermissionCheck>
                            <PermissionCheck permission="Pages.Opportunities"><ListItemLink to="/OpportunityReports" primary="Opportunity Reports" icon={<AssessmentIcon className={classes.icon} />} show={show} /></PermissionCheck>
                            <PermissionCheck permission="Pages.ManagementReports"><ListItemLink to="/ManagementReports" primary="Management Reports" icon={<AssessmentIcon className={classes.icon} />} show={show} /></PermissionCheck>
                            <PermissionCheck permission="Pages.Teams"><ListItemLink to="/Teams" primary="Teams" icon={<GroupIcon className={classes.icon} />} show={show} /></PermissionCheck>
                            <PermissionCheck permission=""><ListItemLink to="/Admin" primary="Admin" icon={<AccountCircleIcon className={classes.icon} />} show={show} /></PermissionCheck>
                            <PermissionCheck permission="Pages.PowerBIReports"><ListItemLink to="/PowerBI" primary="PowerBI" icon={<AssessmentIcon className={classes.icon} />} show={show} /></PermissionCheck>
                            <PermissionCheck permission=""><ListItemLink to="/Commissions" primary="Commissions" icon={<AccountBalanceWalletIcon className={classes.icon} />} show={show} /></PermissionCheck>
                            <PermissionCheck permission=""><ListItemLink to="/CommissionGroups" primary="Commission Groups" icon={<AccountBalanceWalletIcon className={classes.icon} />} show={show} /></PermissionCheck>
                            <PermissionCheck permission=""><ListItemLink to="/SuperAdminPowerBI" primary="Super Admin Reports" icon={<AssessmentIcon className={classes.icon} />} show={show} /></PermissionCheck>
                            <PermissionCheck permission=""><ListItemLink to="/Users" primary="Users" icon={<AssignmentIndIcon className={classes.icon} />} show={show} /></PermissionCheck>
                            <PermissionCheck permission=""><ListItemLink to="/Permissions" primary="Permissions" icon={<AssignmentIndIcon className={classes.icon} />} show={show} /></PermissionCheck>
                            <PermissionCheck permission=""><ListItemLink to="/ApprovalRequests" primary="Approval Requests" icon={<ApprovalIcon className={classes.icon} />} show={show} /></PermissionCheck>

                            {show &&
                                <React.Fragment>
                                    <PowerBIEmbed
                                        embedConfig={{
                                            type: 'visual',
                                            id: "f545b34b-ce30-41ea-8e8a-cdfefd653bdb",
                                            embedUrl: "https://app.powerbi.com/reportEmbed?reportId=f545b34b-ce30-41ea-8e8a-cdfefd653bdb",
                                            accessToken: token,
                                            tokenType: models.TokenType.Aad,
                                            settings: {
                                                panes: {
                                                    filters: {
                                                        expanded: false,
                                                        visible: false
                                                    }
                                                },
                                            },
                                            filters: [idFilter],
                                            pageName: "ReportSection55a88d07acf7b90360c1",
                                            visualName: "0e1482f1ee8696dff7bf"
                                        }}

                                        cssClassName={classes.report}

                                        getEmbeddedComponent={
                                            (embeddedReport) => {

                                            }
                                        }

                                    />
                                    <PowerBIEmbed
                                        embedConfig={{
                                            type: 'visual',
                                            id: "f545b34b-ce30-41ea-8e8a-cdfefd653bdb",
                                            embedUrl: "https://app.powerbi.com/reportEmbed?reportId=f545b34b-ce30-41ea-8e8a-cdfefd653bdb",
                                            accessToken: token,
                                            tokenType: models.TokenType.Aad,
                                            settings: {
                                                panes: {
                                                    filters: {
                                                        expanded: false,
                                                        visible: false
                                                    }
                                                },
                                            },
                                            filters: [teamFilter],
                                            pageName: "ReportSection55a88d07acf7b90360c1",
                                            visualName: "2a87de7170b9070c1d0b"
                                        }}

                                        eventHandlers={
                                            new Map([
                                                ['loaded', () => {
                                                    console.log('Report loaded');

                                                }],
                                                ['rendered', () => { console.log('Report rendered'); }],
                                                ['error', (event: any) => {
                                                    console.log(event.detail);
                                                }]
                                            ])
                                        }

                                        cssClassName={classes.report}

                                        getEmbeddedComponent={
                                            (embeddedReport) => {

                                            }
                                        }

                                    />


                                    <PowerBIEmbed
                                        embedConfig={{
                                            type: 'visual',
                                            id: "f545b34b-ce30-41ea-8e8a-cdfefd653bdb",
                                            embedUrl: "https://app.powerbi.com/reportEmbed?reportId=f545b34b-ce30-41ea-8e8a-cdfefd653bdb",
                                            accessToken: token,
                                            tokenType: models.TokenType.Aad,
                                            settings: {
                                                panes: {
                                                    filters: {
                                                        expanded: false,
                                                        visible: false
                                                    }
                                                },
                                            },
                                            filters: [idFilter],
                                            pageName: "ReportSection5c7bc27325567eeb844e",
                                            visualName: "55ab77e05154185f6d57"
                                        }}

                                        eventHandlers={
                                            new Map([
                                                ['loaded', () => {
                                                    console.log('Report loaded');

                                                }],
                                                ['rendered', () => { console.log('Report rendered'); }],
                                                ['error', (event: any) => {
                                                    console.log(event.detail);
                                                }]
                                            ])
                                        }

                                        cssClassName={classes.report}

                                        getEmbeddedComponent={
                                            (embeddedReport) => {

                                            }
                                        }

                                    />
                                    <PowerBIEmbed
                                        embedConfig={{
                                            type: 'visual',
                                            id: "f545b34b-ce30-41ea-8e8a-cdfefd653bdb",
                                            embedUrl: "https://app.powerbi.com/reportEmbed?reportId=f545b34b-ce30-41ea-8e8a-cdfefd653bdb",
                                            accessToken: token,
                                            tokenType: models.TokenType.Aad,
                                            settings: {
                                                panes: {
                                                    filters: {
                                                        expanded: false,
                                                        visible: false
                                                    }
                                                },
                                            },
                                            filters: [teamFilter],
                                            pageName: "ReportSection5c7bc27325567eeb844e",
                                            visualName: "17365c18c1f0365ba0c2"
                                        }}

                                        eventHandlers={
                                            new Map([
                                                ['loaded', () => {
                                                    console.log('Report loaded');

                                                }],
                                                ['rendered', () => { console.log('Report rendered'); }],
                                                ['error', (event: any) => {
                                                    console.log(event.detail);
                                                }]
                                            ])
                                        }

                                        cssClassName={classes.report}

                                        getEmbeddedComponent={
                                            (embeddedReport) => {

                                            }
                                        }

                                    />
                                </React.Fragment>
                            }
                        </List>
                    </div>
                </Drawer>
            </PermissionCheck>
        </React.Fragment >
    );
}
