import * as React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import authService from '../api-authorization/AuthorizeService';
import CircularProgress from '@mui/material/CircularProgress';

interface IDropdownProps {
    id: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

class DropdownViewModel {
    id: string = '';
    value: string = '';
}

export default function StatusDropdown(props: IDropdownProps) {
    const [list, setList] = React.useState<DropdownViewModel[]>([]);
    const [loaded, setLoaded] = React.useState(false);

    React.useEffect(() => {
        setLoaded(false);

        const getData = async () => {
            const token = await authService.getAccessToken();

            const response = await fetch('Campaign/GetStatusDropdown', {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            const data = await response.json();
            setList(data.value);


            setLoaded(true);
        }
        getData();
    }, []);


    const items = list.map((item, index) =>
        <MenuItem key={index} value={item.id}>{item.value}</MenuItem>);

    return (
        <TextField
            select
            label="Status"
            name="statusId"
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={props.onChange}
            value={items.length <= 0 || props.id === '' ? '' : props.id}
            InputProps={{
                disabled: !loaded,
                startAdornment: (
                    <React.Fragment>
                        {loaded ? null : <CircularProgress size={20} />}
                    </React.Fragment>
                )
            }}
            size="small"
        >
             <MenuItem value={""}><em>None</em></MenuItem>
            {items}
        </TextField>
    );
}